<template>
  <div>
    <!--面包屑-->
    <div class="page-breadcrumb">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/user/member' }"
          >用户列表</el-breadcrumb-item
        >
        <el-breadcrumb-item>详情</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <!--内容-->
    <div class="page-content-x">
      <el-tabs :activeName="activeName" @tab-click="onTabs">
        <el-tab-pane label="基本信息" name="info">
          <Info 
        /></el-tab-pane>
        <el-tab-pane label="优惠券情况" name="coupon"
          ><CouponeList ref="coupone" />
        </el-tab-pane>
        <el-tab-pane label="订单情况" name="order"><Order /> </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import Order from "./page/Order.vue";
import CouponeList from "./page/CouponList.vue";
import Info from "./page/Info.vue";
export default {
  components: { Order, CouponeList, Info },
  data() {
    return {
      activeName: "info",
    };
  },
  methods: {
    onTabs(tab) {
      if (tab.paneName === "coupon") {
        this.$refs.coupone.init();
      }
    },
  },
};
</script>
