<template>
  <div>
    <!--内容-->
    <div class="page-content-x">
      <!--搜索-->
      <div v-show="issearch" class="page-content-search">
        <el-form
          ref="form"
          :model="search"
          size="medium"
          :label-width="this.env.search_label_width"
        >
          <el-row>
            <!--                        <el-col :span="6">-->
            <!--                            <el-form-item label="所属公司">-->
            <!--                                <el-input v-model="search.company_name"></el-input>-->
            <!--                            </el-form-item>-->
            <!--                        </el-col>-->
            <el-col :span="6">
              <el-form-item label="商品名称">
                <el-input v-model="search.good_title"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="订单号">
                <el-input v-model="search.order_no"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row class="page-content-search-button">
            <el-col :span="24">
              <el-form-item>
                <el-button
                  icon="el-icon-search"
                  size="medium"
                  type="primary"
                  @click="is_search"
                  >搜索
                </el-button>
                <el-button
                  icon="el-icon-circle-close"
                  size="medium"
                  @click="init"
                  >清空搜索</el-button
                >
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <!--标题-->
      <el-row>
        <el-col :span="12"
          ><h3>{{ page_name }}</h3></el-col
        >
        <el-col :span="12">
          <div class="top-operation-button">
            <el-button
              v-if="issearch"
              icon="el-icon-caret-top"
              size="medium"
              type="primary"
              @click="issearch = !issearch"
              >隐藏搜索
            </el-button>
            <el-button
              v-else
              icon="el-icon-caret-bottom"
              size="medium"
              type="primary"
              @click="issearch = !issearch"
              >显示搜索
            </el-button>
          </div>
        </el-col>
      </el-row>
      <div style="height: 20px;"></div>
      <!--列表-->
      <el-table
        size="medium"
        border
        v-loading="loading"
        :data="tableData"
        style="width: 100%"
      >
        <el-table-column prop="company_name" label="所属公司" width="200">
        </el-table-column>
        <el-table-column prop="title" label="商品名称" width="200">
        </el-table-column>
        <el-table-column prop="order_no" label="订单号" width="100">
        </el-table-column>
        <el-table-column prop="order_no" label="订单状态" width="100">
          <template slot-scope="scope">
            <el-tag size="mini" v-if="scope.row.order_state === 1">正常</el-tag>
            <el-tag size="mini" v-if="scope.row.order_state === 2"
              >核销完成</el-tag
            >
            <el-tag
              size="mini"
              v-if="scope.row.order_state === 5"
              type="warning"
              >取消</el-tag
            >
          </template>
        </el-table-column>
        <el-table-column prop="store_uuid" label="适用门店" width="200">
          <template slot-scope="scope">
            <el-tag
              :key="item.index"
              v-for="item in scope.row.store_uuid_all"
              class="form-tag_colour-tag"
              type="info"
              size="mini"
            >
              {{ item.store_name }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="order_create_time" label="创建日期" width="100">
        </el-table-column>
        <el-table-column prop="expiration_time" label="截止有效期" width="100">
        </el-table-column>
        <el-table-column prop="phone" label="用户手机号" width="100">
        </el-table-column>
        <el-table-column prop="user_nick" label="用户昵称" width="100">
        </el-table-column>
        <el-table-column prop="total_money" label="商品价格" width="100">
          <template slot-scope="scope">
            <span>￥{{ scope.row.total_money }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="good_num" label="数量" width="100">
        </el-table-column>
        <el-table-column prop="pay_money" label="支付金额" width="100">
          <template slot-scope="scope">
            <span>￥{{ scope.row.pay_money }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="" label="退款状态/金额/单号" width="200">
          <template slot-scope="scope">
            <el-tag size="mini" v-if="scope.row.refund_state === 1"
              >退款成功</el-tag
            >
            <span v-if="scope.row.refund_state === 1"
              >/￥{{ scope.row.refund_money }}</span
            >
            <span v-if="scope.row.refund_state === 1"
              >/{{ scope.row.refund_order_no }}</span
            >

            <el-tag
              size="mini"
              v-if="scope.row.refund_state === 2"
              type="warning"
              >退款中</el-tag
            >
            <el-tag
              size="mini"
              v-if="scope.row.refund_state === 0"
              type="warning"
              >无退款</el-tag
            >
          </template>
        </el-table-column>
        <el-table-column fixed="right" label="操作" width="130">
          <template slot-scope="scope">
            <el-button
              v-if="
                is_auth('product.commodityorder.orderrefund') &&
                  scope.row.order_state === 1 && 
                  scope.row.is_refund === 1
              "
              @click="openRefund(scope.row.order_no)"
              size="mini"
              type="danger"
              >退款
            </el-button>
            <el-button
              v-if="
                is_auth('product.commodityorder.orderrefund') &&
                  scope.row.order_state === 1 && 
                  scope.row.is_refund === 0
              "
              size="mini"
              type="danger"
              disabled
              >不可退款
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <div style="height: 20px;"></div>
      <!--分页-->
      <el-pagination
        @current-change="getlist"
        :page-size="this.env.pageSize"
        :pager-count="7"
        background
        layout="prev, pager, next, total"
        :current-page.sync="page"
        :total="count"
      >
      </el-pagination>
      <!--退款备注-->
      <el-dialog
        title="退款"
        :visible.sync="isRefundShow"
        width="50%"
        :close-on-press-escape="false"
        :close-on-click-modal="false"
      >
        <el-row>
          <el-form size="medium" ref="form" label-width="120px">
            <el-col :span="24">
              <el-form-item label="退款备注">
                <el-input
                  type="textarea"
                  :rows="2"
                  placeholder="请输入内容"
                  :maxlength="300"
                  v-model="refund_remarks"
                >
                </el-input>
              </el-form-item>
            </el-col>
          </el-form>
        </el-row>
        <el-row style="margin: 10px 0px;">
          <el-button
            v-if="is_auth('product.commodityorder.orderrefund')"
            @click="refund('event')"
            size="medium"
            type="success"
            >退款
          </el-button>
          <el-button @click="refund('close')" size="medium" type="danger"
            >取消
          </el-button>
        </el-row>
      </el-dialog>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      page_name: "商品订单",
      loading: true, // 加载状态
      issearch: true, // 搜索是否展示
      search: {}, // 搜索内容
      count: 0, // 数据总条数
      page: 1, // 当前页数
      tableData: [], // 列表内容

      refund_remarks: "",
      isRefundShow: false,
    };
  },
  // 创建
  created() {
    let user_uuid = this.$route.query.user_uuid;
    this.user_uuid = user_uuid;
  },
  // 安装
  mounted() {},
  methods: {
    // 判断是否为空
    is_empty(auth) {
      return this.Tool.is_empty(auth);
    },
    // 权限判断
    is_auth(auth) {
      return this.Tool.is_auth(auth);
    },
    // 初始化
    init() {
      this.search = {
        good_title: "",
        order_no: "",
      };
      this.is_search();
    },
    //搜索
    is_search() {
      this.page = 1;
      this.getlist();
    },
    // 获取列表
    getlist() {
      let postdata = {
        api_name: "product.commodityorder.getlist",
        token: this.Tool.get_l_cache("token"),
        pagesize: this.env.pageSize,
        page: this.page,
        user_uuid: this.user_uuid,
      };
      // 非必须参数（搜索条件）
      Object.assign(postdata, this.search);
      // 判断时间条件
      this.loading = true;
      this.Tool.post_data("oss", postdata, (json) => {
        this.loading = false;
        if (json.code === 0) {
          this.tableData = json.data.list;
          this.count = json.data.count;
        } else {
          this.tableData = [];
          this.count = 0;
        }
      });
    },
    // 打开退款弹窗
    openRefund(order_no) {
      this.order_no = order_no;
      this.isRefundShow = true;
    },
    // 退款处理
    refund(type) {
      const { order_no, refund_remarks } = this;
      if (type === "event") {
        let postdata = {
          api_name: "product.commodityorder.orderrefund",
          token: this.Tool.get_l_cache("token"),
          order_no,
          refund_remarks,
          user_uuid: this.user_uuid,
        };
        this.Tool.post_data("oss", postdata, (json) => {
          console.log(json);
          if (json.code === 0) {
            this.$message({
              message: json.message,
              type: "success",
            });
            this.order_no = "";
            this.isRefundShow = false;
            this.refund_remarks = "";
            this.init();
          } else {
            this.Tool.errormes(json);
          }
        });
      } else {
        this.order_no = "";
        this.isRefundShow = false;
        this.refund_remarks = "";
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/*表格不换行*/
>>> .el-table__body .cell {
  white-space: nowrap;
  /*overflow: initial;*/
}

.form-tag_colour-div {
  min-height: 200px;
}
</style>
