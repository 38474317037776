<template>
  <div class="page-content-x">
    <!--标题-->
    <el-tabs :activeName="activeName" type="card" @tab-click="handleClick">
      <el-tab-pane label="团课" name="group">
        <GroupList />
      </el-tab-pane>
      <el-tab-pane label="私教" name="pt">
        <PtList ref="pt" />
      </el-tab-pane>
      <el-tab-pane label="训练营" name="camp">
        <CampList ref="camp" />
      </el-tab-pane>
      <el-tab-pane label="商品" name="commod">
        <CommodList ref="commod" />
      </el-tab-pane>
      <el-tab-pane label="活动" name="activity">
        <ActivityList ref="activity" />
      </el-tab-pane>
      <el-tab-pane label="赛事" name="contest">
        <ContestList ref="contest" />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import GroupList from "./orderPage/groupList.vue";
import PtList from "./orderPage/ptList.vue";
import CampList from "./orderPage/campList.vue";
import CommodList from "./orderPage/commodList.vue";
import ActivityList from "./orderPage/activityList.vue";
import ContestList from "./orderPage/contestList.vue";
export default {
  components: {
    GroupList,
    PtList,
    CampList,
    CommodList,
    ActivityList,
    ContestList
  },
  data() {
    return {
      activeName: "group",
    };
  },
  methods: {
    init() {
      let user_uuid = this.$route.query.user_uuid;
      this.user_uuid = user_uuid;
      this.getCouponList();
    },
    handleClick(tab) {
      if (tab.paneName === "pt") {
        this.$refs.pt.init();
      } else if (tab.paneName === "camp") {
        this.$refs.camp.init();
      } else if (tab.paneName === "commod") {
        this.$refs.commod.init();
      } else if (tab.paneName === "activity") {
        this.$refs.activity.init();
      } else if (tab.paneName === "contest") {
        this.$refs.contest.init();
      }
    },
  },
};
</script>
