<template>
  <div>
    <div class="page-content-search">
      <el-form ref="form" :model="search" size="mini" :label-width="this.env.search_label_width">
        <el-row>
          <el-col :span="6">
            <el-form-item label="订单号">
              <el-input v-model="search.order_no"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="3">
            <el-form-item>
              <el-button icon="el-icon-search" size="mini" type="primary" @click="is_search">搜索
              </el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>

    <el-table size="medium" border v-loading="loading" :data="tableData" style="width: 100%">
      <el-table-column prop="activity_title" label="赛事名称" show-overflow-tooltip>
      </el-table-column>
      <el-table-column prop="user_nick" label="用户名称" width="120" show-overflow-tooltip>
      </el-table-column>
      <el-table-column prop="phone" label="手机号" width="120" show-overflow-tooltip>
      </el-table-column>
      <el-table-column prop="order_no" label="订单号" width="120" show-overflow-tooltip>
      </el-table-column>
      <el-table-column prop="create_time" label="创建时间" width="120" show-overflow-tooltip>
      </el-table-column>
      <el-table-column prop="phone" label="是否退款" width="200">
        <template slot-scope="scope">
          <el-popover v-if="scope.row.refund_state === 1" placement="right" trigger="click" show-overflow-tooltip>
            <div style="line-height: 30px">
              <div>用户名称：{{ scope.row.user_nick }}</div>
              <div>手机号码：{{ scope.row.phone }}</div>
              <div>订单号：{{ scope.row.order_no }}</div>
              <div>退款时间：{{ scope.row.refund_time }}</div>
              <div>退款备注：{{ scope.row.refund_remarks }}</div>
            </div>
            <el-tag size="mini" type="danger" slot="reference">有退款</el-tag>
          </el-popover>
          <el-tag size="mini" v-else type="info">无退款</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="contest_type" label="赛事类型" show-overflow-tooltip>
        <template slot-scope="scope">
          <el-tag size="mini">{{scope.row.contest_type === 1 ? '个人赛' : '团队赛'}}</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="team_title" label="团队名称" width="120" show-overflow-tooltip>
        <template slot-scope="scope">
          <p style="text-align: center;margin:0">{{scope.row.contest_type === 1 ? '/' : scope.row.team_title}}</p>
        </template>
      </el-table-column>
      <el-table-column prop="pay_time" label="支付时间" show-overflow-tooltip>
        <template slot-scope="scope">
          {{ scope.row.pay_time === "1970-01-01 08:00" ? "无" : scope.row.pay_time }}
        </template>
      </el-table-column>
      <el-table-column fixed="right" label="操作" width="130">
        <template slot-scope="scope">
          <el-button v-if="
            is_auth('order.contest.cancel') &&
              scope.row.refund_state !== 1 &&
              scope.row.is_refund === 1
          " @click="openRefund(scope.row.order_no)" size="mini" type="danger">退款
          </el-button>
          <el-button v-if="
            is_auth('order.contest.cancel') &&
              scope.row.is_sign <= 0 &&
              scope.row.refund_state !== 1 &&
              scope.row.is_refund === 0
          " size="mini" type="danger" disabled>不可退款
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <div style="height: 20px;"></div>
    <el-pagination @current-change="getlist" :page-size="this.env.pageSize" :pager-count="7" background
      layout="prev, pager, next, total" :current-page.sync="page" :total="count">
    </el-pagination>
    <!--退款备注-->
    <el-dialog title="退款" :visible.sync="isRefundShow" width="50%" :close-on-press-escape="false"
      :close-on-click-modal="false">
      <el-row>
        <el-form size="medium" ref="form" label-width="120px">
          <el-col :span="24">
            <el-form-item label="退款备注">
              <el-input type="textarea" :rows="2" placeholder="请输入内容" :maxlength="300" v-model="refund_remarks">
              </el-input>
            </el-form-item>
          </el-col>
        </el-form>
      </el-row>
      <el-row style="margin: 10px 0px;">
        <el-button v-if="is_auth('order.contest.cancel')" @click="refund('event')" size="medium" type="success">退款
        </el-button>
        <el-button @click="refund('close')" size="medium" type="danger">取消
        </el-button>
      </el-row>
    </el-dialog>
  </div>
</template>
  
<script>
export default {
  name: "OrderList",
  data() {
    return {
      loading: false, // 加载状态
      count: 0, // 数据总条数
      page: 1, // 当前页数
      tableData: [], // 添加页面数据
      search: {}, // 搜索内容

      refund_remarks: "",
      isRefundShow: false,
    };
  },
  // 创建
  created() {
    let user_uuid = this.$route.query.user_uuid;
    this.user_uuid = user_uuid;
  },
  methods: {
    init() {
      this.count = 0;
      this.tableData = [];
      this.search = {
        order_no: "",
      };
      this.is_search();
    },
    is_search() {
      this.page = 1;
      this.getlist();
    },
    // 权限判断
    is_auth(auth) {
      return this.Tool.is_auth(auth);
    },
    // 获取可选教练列表
    getlist() {
      let postdata = {
        api_name: "order.contest.getlist",
        token: this.Tool.get_l_cache("token"),
        user_uuid: this.user_uuid,
        pagesize: this.env.pageSize,
        page: this.page,
      };
      // 非必须参数（搜索条件）
      Object.assign(postdata, this.search);
      this.loading = true;
      this.Tool.post_data("oss", postdata, (json) => {
        this.loading = false;
        if (json.code === 0) {
          this.tableData = json.data.list;
          this.count = json.data.count;
        } else {
          this.tableData = [];
          this.count = 0;
          this.Tool.errormes(json);
        }
      });
    },
    // 打开退款弹窗
    openRefund(order_no) {
      this.order_no = order_no;
      this.isRefundShow = true;
    },
    // 退款处理
    refund(type) {
      const { order_no, refund_remarks } = this;
      if (type === "event") {
        let postdata = {
          api_name: "order.contest.cancel",
          token: this.Tool.get_l_cache("token"),
          order_no,
          refund_remarks,
          user_uuid: this.user_uuid,
        };
        this.Tool.post_data("oss", postdata, (json) => {
          console.log(json);
          if (json.code === 0) {
            this.$message({
              message: json.message,
              type: "success",
            });
            this.order_no = "";
            this.isRefundShow = false;
            this.refund_remarks = "";
            this.init();
          }
        });
      } else {
        this.order_no = "";
        this.refund_remarks = "";
        this.isRefundShow = false;
      }
    },
  },
};
</script>
  
<style scoped>

</style>
  