<template>
  <div class="page-content-x">
    <el-table :data="list" border size="medium" style="width: 100%">
      <el-table-column
        prop="user_coupon_uuid"
        label="用户优惠券uuid"
        width="200"
      />
      <el-table-column prop="coupon_title" label="优惠券名字" width="200">
        <template slot-scope="scope">
          <!--  -->
          <el-link
            v-if="is_auth('coupon.library.getinfo')"
            type="primary"
            @click="goToCouponDetail(scope.row.coupon_uuid)"
          >
            <span>{{ scope.row.coupon_title }}</span>
          </el-link>
          <span v-else>{{ scope.row.coupon_title }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="coupon_money" label="优惠券金额" width="100">
        <template slot-scope="scope">
          <span>¥{{ scope.row.coupon_money }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="coupon_type" label="获得渠道" width="160">
        <template slot-scope="scope">
          <el-tag size="mini" v-if="scope.row.type === 1">购买券包</el-tag>
          <el-tag size="mini" v-if="scope.row.type === 2">扫码领取</el-tag>
          <el-tag size="mini" v-if="scope.row.type === 3"
            >平台赠送（补偿）</el-tag
          >
          <el-tag size="mini" v-if="scope.row.type === 4">兑换</el-tag>
          <el-tag size="mini" v-if="scope.row.type === 5">邀请有礼</el-tag>
          <el-tag size="mini" v-if="scope.row.type === 6">新用户</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="coupon_state" label="优惠券状态" width="100">
        <template slot-scope="scope">
          <el-tag size="mini" v-if="scope.row.state === 1">正常</el-tag>
          <el-tag size="mini" v-if="scope.row.state === 2" type="success"
            >已使用</el-tag
          >
        </template>
      </el-table-column>
      <!-- <el-table-column prop="order_no" label="优惠券订单" width="200" /> -->
      <el-table-column prop="use_time" label="使用时间">
        <template slot-scope="scope">
          <span>{{
            scope.row.use_time === "1970-01-01 08:00"
              ? "未使用"
              : scope.row.use_time
          }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="create_time" label="创建时间" width="200" />
      <el-table-column prop="expiration_time" label="到期时间" width="200" />
    </el-table>
    <!--分页-->
    <el-pagination
      style="margin-top:10px"
      @current-change="getCouponList"
      :page-size="this.env.pageSize"
      :pager-count="7"
      background
      layout="prev, pager, next, total, jumper"
      :current-page.sync="page"
      :total="count"
    >
    </el-pagination>
  </div>
</template>

<script>
export default {
  data() {
    return {
      user_uuid: "",
      list: [],
      page: 1,
      count: 0,
    };
  },
  methods: {
    // 权限判断
    is_auth(auth) {
      return this.Tool.is_auth(auth);
    },
    init() {
      let user_uuid = this.$route.query.user_uuid;
      this.user_uuid = user_uuid;
      this.getCouponList();
    },
    // 获取优惠券列表数据
    getCouponList() {
      let postdata = {
        api_name: "user.memberinfo.getcouponlist",
        token: this.Tool.get_l_cache("token"),
        user_uuid: this.user_uuid,
        pagesize: this.env.pageSize,
        page: this.page,
      };
      this.Tool.post_data("oss", postdata, (json) => {
        this.loading = false;
        if (json.code === 0) {
          this.list = json.data.list;
          this.count = json.data.count;
        } else {
          this.Tool.errormes(json);
        }
      });
    },
    goToCouponDetail(id) {
      this.$router.push({
        path: "/coupon/library/info",
        query: { coupon_uuid: id },
      });
    },
  },
};
</script>

<style scoped>
/*表格不换行*/
>>> .el-table__body .cell {
  white-space: nowrap;
  /*overflow: initial;*/
}
</style>
