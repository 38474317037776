<template>
  <div>
    <!--标题-->
    <el-row style="margin-top:20px">
      <el-col :span="16">
        <el-form size="medium" :label-width="this.env.label_width">
          <el-form-item label="用户头像：">
            <Qnupload v-model="info.avatar_url" :readonly="true" />
          </el-form-item>
          <el-form-item label="昵称：">
            <span class="form-span-text">{{ info.user_nick }}</span>
          </el-form-item>
          <el-form-item label="手机号：">
            <span class="form-span-text">{{ info.phone }}</span>
          </el-form-item>
          <el-form-item label="注册运动品类：">
            <div style="display: inline-block;margin-left:4px;" v-for="(item, index) in info.motion_category"
              :key="index">
              <el-tag v-if="item" size="mini" type="info">{{item}}</el-tag>
            </div>
          </el-form-item>
          <el-form-item label="注册目标品类：">
            <div style="display: inline-block;margin-left:4px;" v-for="(item, index) in info.target_category"
              :key="index">
              <el-tag v-if="item" size="mini" type="info">{{item}}</el-tag>
            </div>
          </el-form-item>
          <el-form-item label="是否注册成功：">
            <span class="form-span-text">{{ info.phone ? '是': '否' }}</span>
          </el-form-item>
          <el-form-item label="注册成功时间：">
            <span class="form-span-text">{{ info.phone ? info.update_time : ""}}</span>
          </el-form-item>
          <el-form-item label="关注时间：">
            <span class="form-span-text">{{ info.create_time }}</span>
          </el-form-item>

        </el-form>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import Qnupload from '@/components/Qnupload'
export default {
  components: {
    Qnupload
  },
  data() {
    return {
      user_uuid: "",
      info: {},
    };
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      let user_uuid = this.$route.query.user_uuid;
      console.log(user_uuid);
      this.user_uuid = user_uuid;
      this.getUserInfo();
    },
    getUserInfo() {
      let postdata = {
        api_name: "user.member.getinfo",
        token: this.Tool.get_l_cache("token"),
        user_uuid: this.user_uuid,
      };
      this.Tool.post_data("oss", postdata, (json) => {
        this.loading = false;
        if (json.code === 0) {
          this.info = json.data;
        } else {
          this.Tool.errormes(json);
        }
      });
    },
  },
};
</script>
